import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';


const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

<p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 28 Februari
                - 3 Video Series dan Disc Up To 60% Early Bird Attraction Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="77" />
            <input type="hidden" name="f" value="77" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};


export default function Four(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };

    return (
        <>

           <Container fluid className="__bg-secondary-miror">
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline _text-drak text-center  __margin-bottom-5-wl">
                            Solusi Buat Dia Tertarik Balik Sama Kamu <br/>
                            Tanpa Nembak dan Tanpa Takut Ditolak
                        </h2>
                    </Row>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__image_happy_woman">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/women-sec-bawah.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__text-desc mt-3">
                                {/* <h2 className="__text-headline __text-drak">
                                    Pernahkah kamu?
                                </h2> */}
                                <p className="text-left">
                                    Attraction Mastery adalah sebuah Training Romansa yang
                                    diadakan secara virtual dan dibawakan oleh ahlinya!
                                    Perkenalkan Jose Aditya, seorang Pelatih Percintaan wanita
                                    #1 Indonesia
                                </p>
                                <p className="text-left">
                                    Dengan teknik yang easy to apply, proven from the expert,
                                    and mind-blowing. SPESIAL untuk kamu yang ingin buat dia
                                    suka balik sama kamu tanpa nembak dan takut ditolak! 
                                </p>
                                <p className="text-left">
                                    Cari tahu cara menanam benih cinta di otak pria agar dia
                                    penasaran sama kamu terus dan terus setelah klik Join
                                    Waiting List Attraction Mastery! 
                                </p>
                                <div className="__flex-mobile">
                                <Button variant="primary" size="lg"  onClick={handleShow}>
                                    JOIN WAITING LIST
                                </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center __margin-my-wl">
                        <Col lg={12} sm={12} md={12} xs={12}>
                            <div className="__bg-small-primary">
                                <p>
                                    Bagaimana jika Ada SOLUSI yang Memudahkan Kamu
                                    Menanam Benih Ketertarikan pada Pria Incaranmu
                                    hingga Buat Dia Tertarik Balik Denganmu?
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center __margin-my-wl">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="_mockup-am">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/Devices.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__text-desc _w-text-desc">
                                <h2 className="__text-headline __text-drak text-center">
                                    Perkenalkan Attraction Mastery
                                </h2>
                                <p className="text-center">
                                    Sebuah Love Virtual Training yang bisa kamu gunakan sebagai panduan untuk memudahkan kamu <br/>
                                    menanam benih cinta yang tidak diketahui wanita kebanyakan!
                                </p>
                                <p className="text-center">
                                    Bayangkan betapa indahnya saat kamu kuasai teknik menanam benih cinta, memupuknya dengan  <br/>
                                    kasih sayang lalu tumbuh menjadi hubungan yang diimpikan.
                                </p>
                                <p className="text-center">
                                    Bayangkan betapa bahagianya jika pria yang kamu sukai menyukaimu balik tanpa melewati proses  <br/>
                                    yang rumit dan takut ditolak. 
                                </p>
                                <p className="text-center">
                                    Dengan Join VIP Waiting List ini, Kamu akan merasakan beberapa materi dari Attraction Mastery yang  <br/>
                                    akan diadakan pada bulan Maret 2021 mendatang.
                                </p>
                                <p className="text-center">
                                    Lebih dari 225++ alumni Attraction Mastery telah merasakan perubahan di kehidupan romansa  <br/>
                                    mereka hanya karena mengikuti materi cemerlang ini! 
                                </p>
                                <p className="text-center">
                                    <b>
                                        Klik Join Waiting List sekarang agar kamu mengalaminya sendiri!
                                    </b>
                                </p>
                                <div className="d-flex justify-content-center">
                                    <Button variant="primary" size="lg"  onClick={handleShow}>
                                            JOIN WAITING LIST
                                    </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>

        </>
    )
}